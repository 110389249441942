import paper from 'paper'

class paperjs{

    constructor( gsap, ScrollTrigger ){
        
        let coordinates = []
        let coord_count = -1

        const m900 = window.matchMedia('(min-width: 901px)')

        if (m900.matches) {

        // points from navigation

            coordinates.push([])
            coord_count++

            const logo = document.querySelector('.topbar .mainlogo > div > a')
            let logo_center = this.getBoundingBox(logo)
            let logo_center_arr = [logo_center.xCenter, logo_center.yCenter]
            coordinates[coord_count].push(logo_center_arr)

            const lang = document.querySelector('.subnav .languages > a')
            let lang_center = this.getBoundingBox(lang)
            let lang_center_arr = [lang_center.xCenter, lang_center.yCenter]
            coordinates[coord_count].push(lang_center_arr)

        // points from main (sections)

            const sections = document.querySelectorAll('body > main > section:not(.add_next):not(.add_prev)')
            for(var s = 0; s < sections.length; s++){

                const section = sections[s]
                const figures = section.querySelectorAll('.content_container figure')
                
                if(section.querySelector('.block.slideshow')){

                    coordinates.push([])
                    coord_count++

                    // if(section.querySelector('.block.newsticker')){
    
                        let button = section.querySelector('.block.slideshow nav a.next_slide')
                        let button_center = this.getBoundingBox(button)
                        let button_center_arr = [button_center.xCenter, button_center.yCenter]
                        coordinates[coord_count].push(button_center_arr)
    
                        let slide = section.querySelector('.block.slideshow .slider > li:first-of-type')
                        let slide_center = this.getBoundingBox(slide)
                        let slide_center_arr = [slide_center.xCenter, slide_center.yCenter]
                        coordinates[coord_count].push(slide_center_arr)

                    // }

                }else if(section.querySelector('.block.image_masonry')){

                    coordinates.push([])
                    coord_count++

                    let line_pos = false
                    let grid_images = section.querySelector('.block.image_masonry').querySelectorAll(".image")
                    grid_images.forEach((img) => {

                        const figure = img.querySelector('figure').parentNode
                        let figure_center = this.getBoundingBox(figure)
                        let figure_center_arr = [figure_center.xCenter, figure_center.yCenter]
                        
                        let pos = img.getBoundingClientRect().top
                        
                        if(line_pos == false){ line_pos = pos }
                        if(line_pos != pos){

                            line_pos = pos

                            coordinates.push([])
                            coord_count++

                        }

                        coordinates[coord_count].push(figure_center_arr)

                    })

                }else if(figures.length > 0){

                    coordinates.push([])
                    coord_count++

                    for(var f = 0; f < figures.length; f++){
                        
                        const figure = figures[f].parentNode
                        let figure_center = this.getBoundingBox(figure)
                        let figure_center_arr = [figure_center.xCenter, figure_center.yCenter]
                        coordinates[coord_count].push(figure_center_arr)
        
                    }

                }

            }

        // points from footer

            coordinates.push([])
            coord_count++

            const footer_logo = document.querySelector('footer .contactinfo .logo img')
            let footer_logo_center = this.getBoundingBox(footer_logo)
            let footer_logo_center_arr = [footer_logo_center.xCenter, footer_logo_center.yCenter]
            coordinates[coord_count].push(footer_logo_center_arr)

            const langfooter_card = document.querySelector('footer .contactinfo .card')
            let langfooter_card_center = this.getBoundingBox(langfooter_card)
            let langfooter_card_center_arr = [langfooter_card_center.xCenter, langfooter_card_center.yCenter]
            coordinates[coord_count].push(langfooter_card_center_arr)

        }

        // draw lines

            function initPaper(){
                
                // the canvas
                const canvas = document.getElementById('cc')
                canvas.getContext('2d')
                const canvasAspectRatio = canvas.width / canvas.height
                paper.setup(canvas)
                if(document.querySelector('#co_diagram')){
                    let diagram_canvas = document.getElementById('co_diagram')
                    paper.setup(diagram_canvas)
                }

                paper.projects[0].activate()

                if (m900.matches) {

                    // for every section (or header / footer)
                    for(var p = 0; p < coordinates.length; p++){

                        // if header (and first section is not text)
                        if(p == 0 && document.querySelector('section.start') && !document.querySelector('section.start').classList.contains('text_intro')){

                            for(var s = 0; s < coordinates[p].length; s++){
                            
                                let startpoint = { }
                                startpoint.x = coordinates[p][s][0]
                                startpoint.y = coordinates[p][s][1]

                                for(var e = 0; e < coordinates[p+2].length; e++){
                                    let endpoint = { }
                                    endpoint.x = coordinates[p+2][e][0]
                                    endpoint.y = coordinates[p+2][e][1]
                                    let path = new paper.Path({
                                        segments: [[startpoint.x, startpoint.y], [endpoint.x, endpoint.y]]
                                    })
                                    if(document.querySelector('body[data-pagetitle="Nachhaltigkeit"]') || document.querySelector('body[data-pageparent="Nachhaltigkeit"]')){
                                        path.strokeColor = 'rgb(151, 172, 150)'
                                    }else if(document.querySelector('body[data-pagetitle="Innovations-Werkstatt"]') || document.querySelector('body[data-pageparent="Innovations-Werkstatt"]')){
                                        path.strokeColor = '#E180B2'
                                    }else{
                                        path.strokeColor = '#D9D9D9'
                                    }
                                    path.strokeWidth = 1
                                    path.dashArray = [6, 4]
                                }
                            
                            }

                        }else if(p + 1 < coordinates.length){
                        // if not last section

                            // for every point in this section
                            for(var s = 0; s < coordinates[p].length; s++){
                            
                                // where the line starts
                                let startpoint = { }
                                startpoint.x = coordinates[p][s][0]
                                startpoint.y = coordinates[p][s][1]

                                // for every point of the next section
                                for(var e = 0; e < coordinates[p+1].length; e++){
                            
                                    // where the line ends
                                    let endpoint = { }
                                    endpoint.x = coordinates[p+1][e][0]
                                    endpoint.y = coordinates[p+1][e][1]

                                    // path settings
                                    let path = new paper.Path({
                                        segments: [[startpoint.x, startpoint.y], [endpoint.x, endpoint.y]]
                                    })
                                    if(document.querySelector('body[data-pagetitle="Nachhaltigkeit"]') || document.querySelector('body[data-pageparent="Nachhaltigkeit"]')){
                                        path.strokeColor = 'rgb(151, 172, 150)'
                                    }else if(document.querySelector('body[data-pagetitle="Innovations-Werkstatt"]') || document.querySelector('body[data-pageparent="Innovations-Werkstatt"]')){
                                        path.strokeColor = '#E180B2'
                                    }else{
                                        path.strokeColor = '#D9D9D9'
                                    }
                                    path.strokeWidth = 1
                                    path.dashArray = [6, 4]

                                }

                                // if this and next section have only a single image, draw additional lines to section after next
                                if(p + 2 < coordinates.length && coordinates[p].length == 1 && coordinates[p+1].length == 1){
                                    for(var e = 0; e < coordinates[p+2].length; e++){
                                        let endpoint = { }
                                        endpoint.x = coordinates[p+2][e][0]
                                        endpoint.y = coordinates[p+2][e][1]
                                        let path = new paper.Path({
                                            segments: [[startpoint.x, startpoint.y], [endpoint.x, endpoint.y]]
                                        })
                                        if(document.querySelector('body[data-pagetitle="Nachhaltigkeit"]') || document.querySelector('body[data-pageparent="Nachhaltigkeit"]')){
                                            path.strokeColor = 'rgb(151, 172, 150)'
                                        }else if(document.querySelector('body[data-pagetitle="Innovations-Werkstatt"]') || document.querySelector('body[data-pageparent="Innovations-Werkstatt"]')){
                                            path.strokeColor = '#E180B2'
                                        }else{
                                            path.strokeColor = '#D9D9D9'
                                        }
                                        path.strokeWidth = 1
                                        path.dashArray = [6, 6]
                                    }
                                }
                            
                            }

                        }

                    }
                    paper.projects[0].view.draw()
            
                }

            if(document.querySelector('#co_diagram')){
                
                paper.projects[1].activate()

                // basics
                let co_canvas_block = document.querySelector('.co_diagram')
                let co_canvas = document.querySelector('.co_diagram canvas')

                co_canvas_block.querySelector('.paper_js').addEventListener('mouseleave', () => {
                
                    document.querySelector('div.co_values > div:first-of-type').classList.remove("active")
                
                    document.querySelector('div.co_values span.actual').innerHTML = null
                    document.querySelector('div.co_values .co span:last-of-type').innerHTML = null
                
                    usage_real_paths.forEach(function(element) { element.opacity = 0.5 })
                    co_outputs_paths.forEach(function(element) { element.opacity = 1 })
                
                })

                document.querySelector(".show_real").addEventListener('mouseenter', () => {
                    usage_real_paths.forEach(function(element){ element.opacity = 0.75 })
                    co_outputs_paths.forEach(function(element){ element.opacity = 0 })
                })
                document.querySelector(".show_real").addEventListener('mouseleave', () => {
                    usage_real_paths.forEach(function(element){ element.opacity = 0.5 })
                    co_outputs_paths.forEach(function(element){ element.opacity = 1 })
                })

                document.querySelector(".show_co").addEventListener('mouseenter', () => {
                    usage_real_paths.forEach(function(element){ element.opacity = 0 })
                })
                document.querySelector(".show_co").addEventListener('mouseleave', () => {
                    usage_real_paths.forEach(function(element){ element.opacity = 0.5 })
                })

                let co_canvas_w = co_canvas.offsetWidth
                let co_canvas_h = co_canvas.offsetHeight - 32

                // values
                let years = co_canvas.getAttribute('data-jahr').split(",").reverse()
                let usage_real = co_canvas.getAttribute('data-verbrauch').split(",").reverse()
                let co_output = co_canvas.getAttribute('data-ausstoss').split(",").reverse()

                // constructors
                let number_of_values = years.length
                let section_size = co_canvas_w / number_of_values
                let scala_size = co_canvas_h / 20000000 // max value currently between 18000000 and 19000000

                let usage_real_paths = []
                let real_y_values = []

                let co_outputs_paths = []
                let co_y_values = []

                // do it

                let frame_path = new paper.Path()
                frame_path.add(new paper.Point(0, 0), new paper.Point(0, co_canvas_h), new paper.Point((number_of_values * section_size), co_canvas_h))
                frame_path.strokeColor = '#000000'

                for (let i = 0; i < number_of_values; i++) {

                    let li_tag_year = document.createElement("li")
                    li_tag_year.innerHTML = years[i]
                    document.querySelector('.co_diagram ul.years').appendChild(li_tag_year)

                    let x_start = i * section_size
                    let x_end = (i + 1) * section_size

                    // squares for graph

                    // real

                    let target_real = usage_real[i]
                    let pos_y_real = co_canvas_h - (target_real * scala_size)
                    real_y_values.push(pos_y_real)

                    let new_real_path = new paper.Path()
                    new_real_path.add(new paper.Point(x_start, co_canvas_h), new paper.Point(x_start, co_canvas_h), new paper.Point(x_end, co_canvas_h), new paper.Point(x_end, co_canvas_h))
                    
                    new_real_path.closed = true
                    new_real_path.fillColor = '#97AC96'
                    new_real_path.strokeColor = '#5f6f63'
                    new_real_path.opacity = 0.5

                    usage_real_paths.push( new_real_path )

                    // co2

                    let target_calculated = co_output[i]
                    let pos_y_calculated = co_canvas_h - ((target_calculated * 200) * scala_size)
                    co_y_values.push(pos_y_calculated)
                    
                    let new_calculated_path = new paper.Path()
                    new_calculated_path.add(new paper.Point(x_start, co_canvas_h), new paper.Point(x_start, co_canvas_h), new paper.Point(x_end, co_canvas_h), new paper.Point(x_end, co_canvas_h))
                    
                    new_calculated_path.closed = true
                    new_calculated_path.fillColor = '#97AC96'
                    new_calculated_path.strokeColor = '#5f6f63'

                    co_outputs_paths.push( new_calculated_path )

                }

                paper.projects[1].view.draw()

                usage_real_paths.forEach(function(element,r){
                    element.onMouseEnter = function() {
                        if(co_canvas_block.classList.contains("ready")){
                    
                            document.querySelector('div.co_values > div:first-of-type').classList.add("active")
                    
                            document.querySelector('div.co_values span.actual').innerHTML = parseInt(usage_real[r]).toLocaleString('de-DE')
                            document.querySelector('div.co_values .co span:last-of-type').innerHTML = parseInt(co_output[r]).toLocaleString('de-DE')
                    
                            usage_real_paths.forEach(function(element, index) {
                                if(index != r){ element.opacity = 0.25 }
                                else{ element.opacity = 0.5 }
                            })
                            co_outputs_paths.forEach(function(element, index) {
                                if(index != r){ element.opacity = 0.5 }
                                else{ element.opacity = 1 }
                            })
                    
                        }
                    }
                })
                co_outputs_paths.forEach(function(element,r){
                    element.onMouseEnter = function() {
                        if(co_canvas_block.classList.contains("ready")){
    
                            document.querySelector('div.co_values > div:first-of-type').classList.add("active")
    
                            document.querySelector('div.co_values span.actual').innerHTML = parseInt(usage_real[r]).toLocaleString('de-DE')
                            document.querySelector('div.co_values .co span:last-of-type').innerHTML = parseInt(co_output[r]).toLocaleString('de-DE')
    
                            usage_real_paths.forEach(function(element, index) {
                                if(index != r){ element.opacity = 0.25 }
                                else{ element.opacity = 0.5 }
                            })
                            co_outputs_paths.forEach(function(element, index) {
                                if(index != r){ element.opacity = 0.5 }
                                else{ element.opacity = 1 }
                            })
    
                        }
                    }
                })

                ScrollTrigger.create({
                
                    trigger: co_canvas,
                    start: "top " + (co_canvas.offsetTop + (window.innerHeight / 2)), // element viewport
                    onEnter: function(){

                        if(!co_canvas_block.classList.contains("ready")){
                            co_canvas_block.classList.add("ready")

                            // loop through graph to fade in
                            for (let r = 0; r < number_of_values; r++) {

                                (function(index){
                                    setTimeout(function(){
    
                                        setTimeout(function(){

                                            gsap.to(usage_real_paths[r].segments[1].point, {
                                                duration: .61,
                                                y: real_y_values[r],
                                                ease: "Power2.easeOut"
                                            })
    
                                            gsap.to(usage_real_paths[r].segments[2].point, {
                                                duration: .61,
                                                y: real_y_values[r],
                                                ease: "Power2.easeOut"
                                            })
            
                                            setTimeout(function(){

                                                gsap.to(co_outputs_paths[r].segments[1].point, {
                                                    duration: .32,
                                                    y: co_y_values[r],
                                                    ease: "Power2.easeOut"
                                                })
        
                                                gsap.to(co_outputs_paths[r].segments[2].point, {
                                                    duration: .32,
                                                    y: co_y_values[r],
                                                    ease: "Power2.easeOut"
                                                })
            
                                            },116)
                                        },116)

                                    }, index * 160)
                                })(r)

                            }

                        }

                    }

                })

            }

        }

        initPaper()

    }

    getBoundingBox(element){
        const box = element.getBoundingClientRect()
        const ret = { }
        for (const prop in box) { ret[prop] = box[prop] }
        ret.xCenter = (box.left + box.right) / 2
        ret.yCenter = (box.top + box.bottom) / 2
        return ret
    }

}

export default paperjs
